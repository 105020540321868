import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import './InventorySystem.css';

export class InventorySystem extends Component {
    static displayName = InventorySystem.name;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            itemName: '',
            units: 0,
            amount: 0,
            costPerUnit: 0,
            totalCost: 0,
            userEmail: '',
            measurement: '',
            userID: '',
            error: '',
            isAuthenticated: true,
            editingItem: null,
            navigateToFoodWaste: false,
            startDate: '', // New state variable for start date
            endDate: '',    // New state variable for end date
            settingsVisible: false,
            warningsetting: 0,  // New state for setting 1
            critialsetting: 0,  // New state for setting 2
        };
    }

    async componentDidMount() {
        const token = localStorage.getItem('token');

        if (!token) {
            this.setState({ isAuthenticated: false });
            return;
        }

        try {
            const userResponse = await fetch('/user/userdetails', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (userResponse.ok) {
                const user = await userResponse.json();
                this.setState({ userEmail: user.email, userID: user.UserId, isAuthenticated: true });
                this.fetchSettingsByUserId(user.userid);
            } else {
                const errorText = await userResponse.text();
                console.error('Failed to fetch user details', userResponse.status, errorText);
                this.setState({ isAuthenticated: false });
                return;
            }

            const inventoryResponse = await fetch('/inventory/items', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (inventoryResponse.ok) {
                const items = await inventoryResponse.json();
                this.setState({
                    items: items.map(item => ({
                        ...item,
                        itemState: this.calculateItemState(item.amount),
                    }))
                });
            } else {
                const errorText = await inventoryResponse.text();
                this.setState({ error: errorText || 'Failed to fetch items' });
            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred', isAuthenticated: false });
        }
    }
    fetchSettingsByUserId = async (userId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`/inventory/setting?userId=${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const settings = await response.json();
                this.setState({
                    warningsetting: settings.warning,
                    critialsetting: settings.critical,
                },
                    () => this.fetchItems(token)
                );
            } else {
                const errorText = await response.text();
                console.error('Failed to fetch settings:', errorText);
            }
        } catch (error) {
            console.error('Error fetching settings:', error);
        }
    };
    fetchItems = async (token) => {
        try {
            const inventoryResponse = await fetch('/inventory/items', {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (inventoryResponse.ok) {
                const items = await inventoryResponse.json();
                const sortedItems = this.sortItems(items); // Call sort function
                this.setState({ items: sortedItems });
            } else {
                const errorText = await inventoryResponse.text();
                this.setState({ error: errorText || 'Failed to fetch items' });
            }
        } catch (error) {
            console.error('Error fetching items:', error);
            this.setState({ error: 'An unexpected error occurred' });
        }
    };

    sortItems = (items) => {
        return items.map(item => ({
            ...item,
            itemState: this.calculateItemState(item.amount),
        })).sort((a, b) => {
            const stateOrder = { 'critical': 1, 'warning': 2, 'normal': 3 };
            return stateOrder[a.itemState] - stateOrder[b.itemState];
        });
    };
    toggleSettings = () => {
        this.setState(prevState => ({ settingsVisible: !prevState.settingsVisible }));
    };

    handleSettingChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSetting = async () => {
        const { warningsetting, critialsetting, userEmail } = this.state;
        const settings = {
            Email: userEmail,
            Warning: warningsetting,
            Critical: critialsetting,
            CreatedDate: new Date().toISOString(), // Current date in ISO format
            SettingName: 'Default'
        };

        try {
            const token = localStorage.getItem('token');
            const response = await fetch('/inventory/settings', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(settings), // Send settings directly
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText || 'Failed to update settings');
            }

            /* console.log('Settings updated successfully:', await response.json());*/
        } catch (error) {
            console.error('Error updating settings:', error);
            this.setState({ error: 'Failed to update settings' });
        }
    };

    calculateItemState = (units) => {
        const { critialsetting, warningsetting } = this.state
        if (units <= critialsetting /*this.state.critical*/) return 'critical';
        if (units <= warningsetting /*this.state.warning*/) return 'warning';
        return 'normal';
    };

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, this.calculateTotalCost);
    };

    calculateTotalCost = () => {
        const { units, costPerUnit } = this.state;
        const totalCost = units * costPerUnit;
        this.setState({ totalCost });
    };
    handleDateChange = async (e) => {
        this.setState({ [e.target.name]: e.target.value }, async () => {
            const { startDate, endDate, userEmail } = this.state;

            if (startDate && endDate && userEmail) {
                try {
                    const token = localStorage.getItem('token');
                    const query = new URLSearchParams({
                        startDate: startDate,
                        endDate: endDate,
                        email: userEmail
                    }).toString();

                    const response = await fetch(`/inventory/items?${query}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        const items = await response.json();
                        const sortedItems = items.map(item => ({
                            ...item,
                            itemState: this.calculateItemState(item.amount),
                        })).sort((a, b) => {
                            const stateOrder = {
                                'critical': 1,
                                'warning': 2,
                                'normal': 3,
                            };
                            return stateOrder[a.itemState] - stateOrder[b.itemState];
                        });

                        this.setState({ items: sortedItems });
                    } else {
                        const errorText = await response.text();
                        this.setState({ error: errorText || 'Failed to fetch items' });
                    }
                } catch (error) {
                    console.error('Error:', error);
                    this.setState({ error: 'An unexpected error occurred' });
                }
            } else {
                console.error('Start Date, End Date, and User ID must be provided.');
            }
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { itemName, units, measurement, amount, costPerUnit, totalCost, editingItem } = this.state;
        const token = localStorage.getItem('token');

        if (itemName && units >= 0 && amount >= 0 && costPerUnit >= 0 && measurement) {
            const itemData = { itemName, units, amount, costPerUnit, totalCost, measurement };

            try {
                let response;
                if (editingItem) {
                    response = await fetch(`/inventory/update/${editingItem.id}`, {
                        method: 'PUT',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(itemData),
                    });
                } else {
                    response = await fetch('/inventory/add', {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(itemData),
                    });
                }

                if (response.ok) {
                    const updatedItem = await response.json();
                    if (editingItem) {
                        this.setState((prevState) => ({
                            items: prevState.items.map(item => item.id === editingItem.id
                                ? { ...updatedItem, itemState: this.calculateItemState(updatedItem.amount) }
                                : item),
                            itemName: '',
                            units: 0,
                            measurement: '',
                            amount: 0,
                            costPerUnit: 0,
                            totalCost: 0,
                            editingItem: null,
                        }));
                    } else {
                        this.setState((prevState) => ({
                            items: [...prevState.items, { ...updatedItem, itemState: this.calculateItemState(updatedItem.amount) }],
                            itemName: '',
                            units: 0,
                            measurement: '',
                            amount: 0,
                            costPerUnit: 0,
                            totalCost: 0,
                        }));
                    }
                } else {
                    console.error('Failed to save inventory item');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            alert('Please fill out all fields with valid data.');
        }
    };

    handleEdit = (item) => {
        this.setState({
            itemName: item.itemName,
            units: item.units,
            measurement: item.measurement,
            amount: item.amount,
            costPerUnit: item.costPerUnit,
            totalCost: item.totalCost,
            editingItem: item,
        });
    };

    handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('auth');
        this.setState({ isAuthenticated: false, userEmail: '', userID: '' });
    };

    handleFoodwaste = () => {
        this.setState({ navigateToFoodWaste: true });
    };

    handleExport = async () => {
        const { userEmail } = this.state;
        const token = localStorage.getItem('token');

        if (!userEmail) {
            console.error('User Email is not available');
            return;
        }

        try {
            // Modify the endpoint to use userEmail
            const response = await fetch(`/inventory/export-inventory?email=${userEmail}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to export inventory');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${userEmail}_inventory.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error exporting inventory:', error);
            this.setState({ error: 'Failed to export inventory' });
        }
    };

    render() {
        const { itemName, units, settingsVisible, warningsetting, critialsetting, measurement, amount, costPerUnit, totalCost, items, userEmail, navigateToFoodWaste, error, isAuthenticated, editingItem } = this.state;

        if (!isAuthenticated) {
            return <Navigate to="/login" />;
        }
        if (navigateToFoodWaste) {
            return <Navigate to="/foodwaste" />;
        }
        return (
            <div className="inventory-system">
                <h1>Inventory System</h1>
                <p>Welcome, {userEmail}</p>
                <button onClick={this.handleLogout}>Logout</button>
                <button onClick={this.handleFoodwaste}>Food Waste Tracker</button>
                <button onClick={this.handleExport}>Export Inventory to Excel</button>
                <button onClick={this.toggleSettings}>
                    {settingsVisible ? 'Hide Settings' : 'Show Settings'}
                </button>

                {settingsVisible && (
                    <div className="settings-panel">
                        <div className="form-group">
                            <label htmlFor="warningsetting">Warning Setting:</label>
                            <input
                                type="number"
                                id="warningsetting"
                                name="warningsetting"
                                value={warningsetting}
                                onChange={this.handleSettingChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="critialsetting">Critical Setting:</label>
                            <input
                                type="number"
                                id="critialsetting"
                                name="critialsetting"
                                value={critialsetting}
                                onChange={this.handleSettingChange}
                            />
                        </div>
                        <button onClick={this.handleSetting}>Save Settings</button>
                    </div>
                )}

                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="itemName">Item Name:</label>
                        <input
                            type="text"
                            id="itemName"
                            name="itemName"
                            value={itemName}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="units">Units:</label>
                        <input
                            type="number"
                            id="units"
                            name="units"
                            value={units}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="measurement">Measurement:</label> {/* New field for measurement */}
                        <input
                            type="text"
                            id="measurement"
                            name="measurement"
                            value={measurement}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="amount">Amount of Units:</label>
                        <input
                            type="number"
                            id="amount"
                            name="amount"
                            value={amount}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="costPerUnit">Cost per Unit:</label>
                        <input
                            type="number"
                            id="costPerUnit"
                            name="costPerUnit"
                            value={costPerUnit}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="totalCost">Total Cost:</label>
                        <input
                            type="number"
                            id="totalCost"
                            name="totalCost"
                            value={totalCost}
                            readOnly
                        />
                    </div>
                    <button type="submit">{editingItem ? 'Update Item' : 'Add Item'}</button>
                </form>
                <div className="form-group">
                    <label htmlFor="startDate">Start Date:</label>
                    <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={this.state.startDate}
                        onChange={this.handleDateChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="endDate">End Date:</label>
                    <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={this.state.endDate}
                        onChange={this.handleDateChange}
                    />
                </div>
                <h2>Inventory List</h2>
                {error && <p className="inventory-error">{error}</p>}
                {items.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Item Name</th>
                                <th>Units</th>
                                <th>Measurement</th>
                                <th>Amount</th>
                                <th>Cost per Unit</th>
                                <th>Total Cost</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => (
                                <tr key={item.id} className={item.itemState}>
                                    <td>{item.itemName}</td>
                                    <td>{item.units}</td>
                                    <td>{item.measurement}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.costPerUnit}</td>
                                    <td>{item.totalCost}</td>

                                    <td>
                                        <button onClick={() => this.handleEdit(item)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No inventory items available.</p>
                )}
            </div>
        );
    }
}