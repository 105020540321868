import React, { Component } from 'react';
import { Navigate } from 'react-router-dom'; // Import Navigate for redirection
export class FoodWastePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wasteItemName: '',
            wasteQuantity: 0,
            wasteReason: '',
            wasteEmployee: '',
            wasteManager: '',
            wasteDate: '',
            wasteRecords: [],
            editingRecord: null, // State to keep track of the record being edited
            navigateinvreturn: false,
        };
    }

    componentDidMount() {
        this.fetchRecords();
    }

    fetchRecords = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('/foodwaste/records', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const records = await response.json();
                this.setState({ wasteRecords: records });
            } else {
                console.error('Failed to fetch records');
            }
        } catch (error) {
            console.error('Error fetching records:', error);
        }
    };
    handleinvreturn = () => {
        this.setState({ navigateinvreturn: true });
    };
    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleWasteSubmit = async (e) => {
        e.preventDefault();
        const { wasteItemName, wasteQuantity, wasteReason, wasteEmployee, wasteManager, wasteDate, editingRecord } = this.state;
        const token = localStorage.getItem('token');

        const wasteData = { wasteItemName, wasteQuantity, wasteReason, wasteEmployee, wasteManager, wasteDate };

        try {
            let response;
            if (editingRecord) {
                // Update existing record
                response = await fetch(`/foodwaste/update/${editingRecord.id}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(wasteData),
                });
            } else {
                // Add new record
                response = await fetch(`/foodwaste/add`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(wasteData),
                });
            }

            if (response.ok) {
                const updatedRecord = await response.json();
                if (editingRecord) {
                    // Update existing record in the state
                    this.setState((prevState) => ({
                        wasteRecords: prevState.wasteRecords.map(record =>
                            record.id === updatedRecord.id ? updatedRecord : record
                        ),
                        wasteItemName: '',
                        wasteQuantity: 0,
                        wasteReason: '',
                        wasteEmployee: '',
                        wasteManager: '',
                        wasteDate: '',
                        editingRecord: null, // Reset editing state
                    }));
                } else {
                    // Add new record to the state
                    this.setState((prevState) => ({
                        wasteRecords: [...prevState.wasteRecords, updatedRecord],
                        wasteItemName: '',
                        wasteQuantity: 0,
                        wasteReason: '',
                        wasteEmployee: '',
                        wasteManager: '',
                        wasteDate: '',
                    }));
                }
            } else {
                console.error('Failed to save waste record');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    handleEdit = (record) => {
        this.setState({
            wasteItemName: record.wasteItemName,
            wasteQuantity: record.wasteQuantity,
            wasteReason: record.wasteReason,
            wasteEmployee: record.wasteEmployee,
            wasteManager: record.wasteManager,
            wasteDate: record.wasteDate,
            editingRecord: record, // Set the record being edited
        });
    };

    render() {
        const { wasteItemName, wasteQuantity, wasteReason, wasteEmployee, wasteManager, wasteDate, navigateinvreturn, wasteRecords, editingRecord } = this.state;
        if (navigateinvreturn) {
            return <Navigate to="/inventorysystem" />;
        }
        return (
            <div className="food-waste-page">
                <h1>Food Waste Tracker</h1>
                <button onClick={this.handleinvreturn}>Return</button>
                <form onSubmit={this.handleWasteSubmit}>

                    <div className="form-group">
                        <label htmlFor="wasteItemName">Item Name:</label>
                        <input
                            type="text"
                            id="wasteItemName"
                            name="wasteItemName"
                            value={wasteItemName}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="wasteQuantity">Quantity:</label>
                        <input
                            type="text"
                            id="wasteQuantity"
                            name="wasteQuantity"
                            value={wasteQuantity}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="wasteReason">Reason for Waste:</label>
                        <input
                            type="text"
                            id="wasteReason"
                            name="wasteReason"
                            value={wasteReason}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="wasteEmployee">Employee:</label>
                        <input
                            type="text"
                            id="wasteEmployee"
                            name="wasteEmployee"
                            value={wasteEmployee}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="wasteManager">Manager:</label>
                        <input
                            type="text"
                            id="wasteManager"
                            name="wasteManager"
                            value={wasteManager}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="wasteDate">Date: Use Today Date</label>
                        <input
                            type="date"
                            id="wasteDate"
                            name="wasteDate"
                            value={wasteDate}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <button type="submit">
                        {editingRecord ? 'Update Waste Record' : 'Add Waste Record'}
                    </button>
                </form>

                <h2>Waste Records</h2>
                {wasteRecords.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Item Name</th>
                                <th>Quantity</th>
                                <th>Reason</th>
                                <th>Employee</th>
                                <th>Manager</th>
                                <th>Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {wasteRecords.map((record) => (
                                <tr key={record.id}>
                                    <td>{record.wasteItemName}</td>
                                    <td>{record.wasteQuantity}</td>
                                    <td>{record.wasteReason}</td>
                                    <td>{record.wasteEmployee}</td>
                                    <td>{record.wasteManager}</td>
                                    <td>{record.wasteDate}</td>
                                    <td>
                                        <button onClick={() => this.handleEdit(record)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No waste records found.</p>
                )}
            </div>
        );
    }
}